import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Dots } from 'react-activity';

export default function Button({
  to,
  onClick,
  href,
  children,
  className,
  secondary,
  plain,
  disabled,
  isLoading,
  danger,
  ...rest
}) {
  const shared = {
    className: classNames(
      'px-4 py-2 rounded bg-brand font-medium text-center flex items-center justify-center gap-2',
      secondary
        ? 'bg-opacity-5 hover:bg-opacity-10 text-brand'
        : plain
        ? 'bg-opacity-0 hover:bg-opacity-10 text-brand'
        : danger
        ? 'bg-red-100 text-red-700 hover:bg-opacity-50 text-brand'
        : disabled
        ? 'text-white'
        : 'hover:bg-opacity-90 text-white',
      className,
      disabled && 'bg-opacity-50',
    ),
    disabled,
    ...rest,
  };
  if (href) {
    return (
      <a {...shared} href={href}>
        {children}
      </a>
    );
  }
  if (onClick) {
    return (
      <button {...shared} onClick={onClick}>
        {isLoading ? <Dots /> : children}
      </button>
    );
  }
  if (to) {
    return (
      <Link to={to} {...shared}>
        {children}
      </Link>
    );
  }
  return <button {...shared}>{isLoading ? <Dots /> : children}</button>;
}
