import { Box, CircularProgress } from '@material-ui/core';

const Loader = ({ fullScreen = true }) => (
  <Box
    display="flex"
    height={fullScreen ? '100vh' : undefined}
    padding={3}
    flex="1"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress />
  </Box>
);

export default Loader;
