import React from 'react';
import { useField, Field, ErrorMessage } from 'formik';
import StyledErrorMessage from './StyledErrorMessage';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { Close } from '@material-ui/icons';

export default function InputGroup({
  label,
  description,
  options,
  emptyOptionLabel,
  isMulti,
  ...props
}) {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [field, _, helpers] = useField(props);

  const className = classNames('focus:ring-brand pr-8', {
    'opacity-50': props.disabled,
  });

  const content =
    props.as === 'select' ? (
      <Field {...field} {...props} className={className}>
        <option value="">{emptyOptionLabel || t('Select...')}</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
    ) : ['multiSelect', 'autocomplete'].includes(props.as) ? (
      <Select
        isClearable
        isSearchable
        maxMenuHeight={150}
        isMulti={props.as === 'multiSelect'}
        {...field}
        {...props}
        value={
          props.as === 'multiSelect'
            ? options.filter(o => field.value?.includes(o.value))
            : options.find(option => option.value === field.value)
        }
        options={options}
        onChange={e => {
          helpers.setValue(
            props.as === 'multiSelect'
              ? e.map(option => option.value)
              : e.value,
          );
        }}
      />
    ) : props.as === 'tag' ? (
      <TagsInput
        value={field.value}
        onChange={val => helpers.setValue(val)}
        className="bg-white border border-gray-200 rounded p-2"
        focusedClassName="border border-gray-700 p-2"
        tagProps={{
          className:
            'bg-brand rounded text-white inline-block text-md p-2 mr-2',
          classNameRemove: 'cursor-pointer mx-2',
        }}
        inputProps={{
          className: 'text-md border-2',
          placeholder: props.placeHolder,
        }}
        renderTag={({
          getTagDisplayValue,
          key,
          disabled,
          tag,
          classNameRemove,
          onRemove,
          ...other
        }) => (
          <span key={key} {...other}>
            {getTagDisplayValue(tag)}
            {!disabled && (
              <Close
                className={classNameRemove}
                onClick={() => onRemove(key)}
                fontSize="inherit"
              />
            )}
          </span>
        )}
      />
    ) : (
      <Field {...field} {...props} className={className} />
    );

  return (
    <label className="text-left flex grow flex-col space-y-1">
      {label && (
        <p htmlFor={props.id || props.name} className="font-medium">
          {label}
        </p>
      )}
      {content}
      {description && <p className="opacity-50 text-sm ">{description}</p>}
      <ErrorMessage name={props.name} render={StyledErrorMessage} />
    </label>
  );
}
